<!-- =========================================================================================
    File Name: ListDomains.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="vx-card bg-dark p-8">
    <div class="flex flex-wrap items-center">
      <div class="flex-grow">
        <vs-dropdown class="cursor-pointer mr-2" vs-trigger-click>
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }} -
              {{ assignmentData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : assignmentData.length }}
              of {{ totalAssignments }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item @click="gridApi.paginationAssignmentPageSize(20)">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationAssignmentPageSize(50)">
              <span>50</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationAssignmentPageSize(100)">
              <span>100</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationAssignmentPageSize(150)">
              <span>150</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>

        <vs-button @click="addNewData" class="ml-2 mr-2 round" color="primary" type="gradient">Create New Assignment</vs-button>
        <vs-button
          @click="
            showArchived = !showArchived;
            loadProductBuffer();
          "
          class="ml-2 round"
          color="danger"
          type="gradient"
          >{{ showArchived === true ? 'Hide Archived' : 'Show Archived' }}</vs-button
        >
      </div>

      <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
      <vs-input
        @input="updateSearchQuery"
        class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
        placeholder="Search..."
        v-model="searchQuery"
      />
      <!-- <vs-button @click="gridApi.exportDataAsCsv()" class="mb-4 md:mb-0">Export as CSV</vs-button> -->
    </div>

    <!-- AgGrid Table -->
    <ag-grid-vue
      :animateRows="true"
      :columnDefs="columnDefs"
      :components="components"
      :defaultColDef="defaultColDef"
      :enableRtl="$vs.rtl"
      :floatingFilter="true"
      :gridOptions="gridOptions"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :rowData="assignmentData"
      :suppressPaginationPanel="true"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      colResizeDefault="shift"
      ref="agGridTable"
    >
    </ag-grid-vue>

    <vs-pagination :total="totalPages" v-model="currentPage" />
  </div>
</template>

<script>
import TableName from './components/TableName.vue';
import TableBank from './components/TableBank.vue';
import TableActions from './components/TableActions.vue';
import TableID from './components/TableID';
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

export default {
  components: {
    AgGridVue,
    vSelect,
    TableName,
    TableBank,
    TableActions,
    TableID,
  },
  data() {
    return {
      showArchived: false,

      bankData: null,
      assignmentData: [],
      totalAssignments: 0,

      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            const value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableID',
        },
        {
          headerName: 'Name',
          field: 'name',
          filter: true,
          width: 400,
          cellRendererFramework: 'TableName',
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
        },
        {
          headerName: 'Bank',
          field: 'bank',
          filter: true,
          cellRendererFramework: 'TableBank',
        },
        {
          headerName: 'Actions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'TableActions',
          cellRendererParams: {
            loadProductBuffer: this.loadProductBuffer.bind(this),
          },
        },
      ],

      // Cell Renderer Components
      components: {
        TableName,
        TableBank,
        TableActions,
        TableID,
      },
      totalPages: 0,
    };
  },
  watch: {},
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
        this.loadProductBuffer(val);
      },
    },
  },
  methods: {
    addNewData() {
      this.$router.push(`/a/examedge/assignments/new?bank=${this.$route.query.bank}`);
    },
    assignmentColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.assignmentModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery() {
      this.currentPage = 0;
    },
    loadProductBuffer(page = 0) {
      if (page === 0) {
        page = this.currentPage;
      }

      this.$http
        .get(
          `examedge/assignment?pageSize=${this.paginationPageSize}&page=${page}&filter[bank]=${this.bankData.id}&search=${this.searchQuery}&populate=bank`,
        )
        .then((response) => {
          if (response.data) {
            this.assignmentData = response.data.rows;
            this.totalPages = response.data.totalPages;
            this.totalAssignments = response.data.total;
          }

          this.gridApi.sizeColumnsToFit();
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while loading the assignments';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load assignments',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }

    if (!this.$route.query.bank) {
      this.$router.push('/a/examedge/banks');
    }

    this.$http
      .get(`examedge/bank/${this.$route.query.bank}`)
      .then((response) => {
        if (response.status === 200) {
          this.bankData = response.data;
          this.loadProductBuffer();
        }
      })
      .catch((error) => {
        this.$router.push('/a/examedge/banks');
      });
  },
};
</script>

<style lang="scss"></style>
